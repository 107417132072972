import React, { useState } from 'react';
import Layout from '../components/Layout';

import Sidebar from '../components/Sidebar';
import Classes from './index.module.css';

import arrow_right from '../assets/images/arrow_right.png';
import arrow_left from '../assets/images/arrow_left.png';
import { Link } from 'gatsby';
import satan_blocker from '../assets/images/satan-blocker.png';

const Love = () => {
  const [counter, setCounter] = useState(100);
  //increase counter
  const increase = () => {
    setCounter(count => count + 1);
  };

  //decrease counter
  const decrease = () => {
    setCounter(count => count - 1);
  };

  //reset counter
  const reset = () => {
    setCounter(100);
  };
  return (
    <Layout>
      <Sidebar showTabs="steps" />
      <div div className="w-100">
        <section
          className="resume-section p-3 p-lg-5 d-flex align-items-center"
          id="top"
        >
          <ul>
            <li>
              <h4>Understand Steps of Satan</h4>
              <h6> خطوات الشيطان</h6>
              <ul>
                <li>
                  <a href="https://youtu.be/BmdIxlfyx_M">
                    Intorudction - Click here
                  </a>
                </li>
              </ul>
            </li>

            <ol>
              <p />
              <li>
                <Link to="/heedlessness/">
                  <h6>Heedlessness and wasting time</h6>
                </Link>
                <ul>
                  <li>
                    <a href="https://youtu.be/gLzpcem5wpA" target="blank">
                      To wake up from Negligence منزلة اليقظة - تطبيق منازل
                      السالكين
                    </a>
                  </li>
                  <li>
                    <>
                      <Link to="#stage1">
                        Stages 1-4: Consciousness - commitment - اليقظة - العزم{' '}
                        <span>[9/18/2020]</span>
                      </Link>
                    </>
                  </li>
                </ul>
              </li>
              <p />ß
              <li>
                <h6>Sins</h6>
                <ul>
                  <li>
                    <a href="https://youtu.be/GbgMMPx_nnk" target="blank">
                      Repentance
                    </a>
                  </li>
                  <li>
                    <a href="https://youtu.be/TP-X1ME9Ivs" target="blank">
                      Practicing Repentance 1
                    </a>
                  </li>
                  <li>
                    <a href="https://youtu.be/XhrvEYD8BZY" target="blank">
                      Practicing Repentance 2
                    </a>
                  </li>
                  <li>
                    <a href="https://youtu.be/GbgMMPx_nnk" target="blank">
                      Erasing Our Sins - Deeds to erase Sins
                    </a>
                  </li>
                  <li>
                    <a href="https://youtu.be/vrMqWBvcdT4" target="blank">
                      Erasing Our Sins - Times of Multiplication
                    </a>
                  </li>
                  <li>
                    <a href="https://youtu.be/Tyas5fi3TBE" target="blank">
                      Erasing Our Sins - Calamities and Trials
                    </a>
                  </li>
                  <li>
                    <a href="https://youtu.be/Jh2koRgGDGQ" target="blank">
                      Asking For Forgiveness
                    </a>
                  </li>
                </ul>
              </li>
              <p />
              <li>
                <Link to="/grief/">
                  <h6>Grief, Stress, Depression, Anxiety</h6>
                </Link>
                <Link to="/grief/">Click here for more Details</Link>
              </li>
              <p />
              <li>
                <h6>Calamities, Trauma, and Trials</h6>
                <ul>
                  <li>
                    <a href="https://youtu.be/qZXIqvXfHrI" target="blank">
                      1 Manners Of Trials They Are Cure
                    </a>
                  </li>
                  <li>
                    <a href="https://youtu.be/6u0Ud81RiYw" target="blank">
                      How to become patient during trials and hardship
                    </a>
                  </li>
                  <p />
                  <li>
                    <>
                      <Link to="#patience">
                        The State of Patience - منزلة الصبر{' '}
                        <span className={Classes.blink}>[10/9/2020]</span>
                      </Link>
                    </>
                  </li>
                  <li>
                    <>
                      <Link to="#trust">
                        The State of Trust - منزلة الثقة{' '}
                        <span className={Classes.blink}>[10/2/2020]</span>
                      </Link>
                    </>
                  </li>
                </ul>
              </li>
              <p />
              <li>
                <h6>Covetousness and Greediness</h6>
                <ul>
                  <li>
                    <p />
                    <li>
                      <>
                        <Link to="#gratitude">
                          The State of Gratitude - منزلة الشكر{' '}
                          <span className={Classes.blink}>[11/27/2021]</span>
                        </Link>
                      </>
                    </li>
                  </li>
                </ul>
              </li>
              <p />
              <li>
                <h6>Wrong Priority </h6>
              </li>
              <p />
              <li>
                <h6>Love of Allah and Spiritual Health</h6>
                <ul>
                  <li>
                    <a
                      href="https://myislamnet.medium.com/the-spiritual-states-e47bae43e638"
                      target="blank"
                    >
                      Spiritual Health{' '}
                    </a>
                  </li>
                  <li>
                    <Link to="/love/">
                      The State of Love - منزلة المحبة{' '}
                      <span className={Classes.blink}>[11/13/2020]</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <p />
              <li>
                <Link to="/hypocrite/">
                  <h4>Hypocrisy</h4>
                  <h6> النفاق</h6>
                </Link>
              </li>
            </ol>
            <p />
            <h3>References</h3>
            <ul>
              <li>
                <a href="https://youtu.be/mlXxfExscOQ">
                  خفف همك وغمك وحزنك وكربك بهذه الطرق الروحية"مواجهة الاكتئاب
                  وعدم الرغبة في الحياة{' '}
                </a>
              </li>
            </ul>
          </ul>
        </section>
        <section
          className="resume-section p-3 p-lg-5 d-flex align-items-center"
          id="references"
        >
          <p />
          <h3>References</h3>
          <ul>
            <li>
              <a href="https://youtu.be/mlXxfExscOQ">
                خفف همك وغمك وحزنك وكربك بهذه الطرق الروحية"مواجهة الاكتئاب وعدم
                الرغبة في الحياة{' '}
              </a>
            </li>
          </ul>
        </section>
      </div>
    </Layout>
  );
};

export default Love;
